import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { Container, Typography, Button } from "@mui/material";
import ArrowLeft from "../../Assets/Images/ArrowLeft.svg";
import "../../Styles/Playground.css";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";
import ExpandableProjectGroup from "../../Components/PlaygroundComponents/ExpandableProjectGroup";
import ExpandableSectionHandler from "../../Components/PlaygroundComponents/ExpandableSectionHandler";
import { setExpandedSections } from "../../features/CommunityData/CommunityState";

export default function TeacherViewCommunity() {
  document.title = "CSSoch | Community";
  const { grade } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [communityProjectsData, setCommunityProjectsData] = useState([]);
  const [userId, setUserId] = useState();
  const { setIsAdminDashboardOrTeacherClassess } =
    useContext(PrimaryNavBarContext);
  const [playgroundProjectIdList, setPlaygroundProjectIdList] = useState([]);
  const [helpInstructionIds, setHelpInstructionIds] = useState([]);
  const dispatch = useDispatch();
  const expandedSections = useSelector(
    (state) => state.communityState.expandedSections
  );
  const [noProjectsPublished, setNoProjectsPublished] = useState(true);

  useEffect(() => {
    setIsAdminDashboardOrTeacherClassess(false);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ grade: grade }),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-playground-set`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setHelpInstructionIds(data["helpInstructionIds"]);
      })
      .catch((err) => console.log(err));
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-community-projects`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        let sortedProjects = data?.projects;
        let newExpandedSections = {};
        for (let section in sortedProjects) {
          if (sortedProjects[section].length) {
            setNoProjectsPublished(false);
            newExpandedSections[section] = expandedSections[section] ?? false;
          }
        }
        dispatch(setExpandedSections(newExpandedSections));
        setCommunityProjectsData(sortedProjects);
        setUserId(data?.user_id);
        setIsLoading(false);
        setPlaygroundProjectIdList(data?.playground_game_set_ids?.sort() ?? []);
      });
  }, []);

  const gotoPlayground = (project, type) => {
    const state = {
      projectDetails: project,
      type: type,
      grade: grade,
      helpInstructionIds: helpInstructionIds,
    };
    if (
      project?.projectType === "python" ||
      project?.projectType === "pythonGraphics"
    ) {
      navigate("/playground/python/view", {
        state: state,
      });
    } else {
      navigate("/playground/view", {
        state: state,
      });
    }
  };

  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <div className="community-page">
      <div className="teacher-community-project-container">
        <div className="actions-wrapper">
          <Button
            className="return-community-button"
            onClick={() => navigate("/classes")}
          >
            <span className="playground-return-home d-flex align-items-center">
              <img src={ArrowLeft} alt="" />
              <span className="back-text">Back</span>
            </span>
          </Button>{" "}
          <Typography
            variant="h4"
            gutterBottom
            component="h1"
            className="playground-headings"
            sx={{
              fontSize: "4vh !important",
              fontFamily: "rajdhani-semibold",
            }}
          >
            Community Projects - Grade{grade}
          </Typography>
          <div></div>
        </div>

        <Container className="playground-projects-container" maxWidth={false}>
          <ExpandableSectionHandler initialExpandedSections={expandedSections}>
            {({ expandedSections, handleExpandSection }) => (
              <ExpandableProjectGroup
                projectsSectionWise={communityProjectsData}
                projectsExpandedSection={expandedSections}
                handleExpandSection={handleExpandSection}
                userId={userId}
                gotoPlayground={gotoPlayground}
                playgroundProjectIdList={playgroundProjectIdList}
                noProjectsPublished={noProjectsPublished}
              />
            )}
          </ExpandableSectionHandler>
        </Container>
      </div>
    </div>
  );
}
