import React, { createContext, useState, useEffect, useContext } from "react";

const BroadcastChannelContext = createContext();

export function BroadcastChannelProvider({ children }) {
  const broadcast = new BroadcastChannel("cssoch-broadcast-channel");

  useEffect(() => {
    broadcast.onmessage = (event) => {
      const eventActions = {
        user_logout: () => {
          if (window.location.pathname !== "/") {
            window.location = "/landing";
          }
        },
        user_login: () => {
          if (["/", "/landing"].includes(window.location.pathname)) {
            window.location = "/landing";
          }
        }
      };

      if (event.data in eventActions) {
        eventActions[event.data]();
      }
    };

    return () => {
      broadcast.close();
    };
  }, []);

  const broadcastMessage = (msg) => {
    broadcast.postMessage(msg);
  };

  return (
    <BroadcastChannelContext.Provider value={{ broadcastMessage }}>
      {children}
    </BroadcastChannelContext.Provider>
  );
}

export function useBroadcastChannel() {
  return useContext(BroadcastChannelContext);
}
