const calculateHeight = (instructionRef, setInstructionBoxHeight) => {
  const boxHeight = 64.6262;
  if (instructionRef.current) {
    let textHeight = instructionRef.current.clientHeight;
    if (boxHeight <= textHeight) {
      textHeight += 20;
      setInstructionBoxHeight(textHeight);
    } else {
      setInstructionBoxHeight(textHeight);
    }
  }
};

const openPopupWindow = (popUpWindowHandle, setPopUpWindowHandle, url) => {
  let handle = popUpWindowHandle;
  const windowFeatures =
    "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=620,height=620,dependent";
  const windowName = "cssochNotesWindow";
  if (handle == null || handle.closed) {
    handle = window.open(url, windowName, windowFeatures);
  } else {
    if(handle.location.href !== url) handle.location.href = url;
    handle.focus();
  }
  if (!handle) {
    window.alert(
      "The window wasn't allowed to open. This is likely caused by built-in popup blockers."
    );
  } else {
    localStorage.setItem("popupWindowHandleName", windowName);
  }
  setPopUpWindowHandle(handle);
};

export { calculateHeight, openPopupWindow };
