import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expandedSections: {},
};

export const communityStateSlice = createSlice({
  name: "communityState",
  initialState,
  reducers: {
    setExpandedSections: (state, action) => {
      state.expandedSections = action.payload;
    },
    toggleSection: (state, action) => {
      state.expandedSections = {
        ...state.expandedSections,
        [action.payload]: !state.expandedSections[action.payload],
      };
    },
  },
});

export const { setExpandedSections, toggleSection } =
  communityStateSlice.actions;
export default communityStateSlice.reducer;
