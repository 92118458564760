import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../Components/Loader/Loader";

const useStyles = makeStyles({
  field: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    display: "block !important",
  },
});

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function AddTeacher() {
  const [isLoaded, setisLoaded] = useState(false);
  const [schoolCode, setSchoolCode] = useState();
  const [teacherDetails, setTeacherDetails] = useState({});
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState();
  const [teacherType, setTeacherType] = useState();
  const [status, setStatus] = useState("active");
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [gradeList, setGradeList] = useState();
  const [sectionList, setSectionList] = useState();
  const [grade, setGrade] = useState("");
  const [section, setSection] = useState("");
  const [contactNumber, setContactNumber] = useState();
  const [officialEmail, setOfficialEmail] = useState();
  const [personalEmail, setPersonalEmail] = useState("");
  const [classSection, setClassSection] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [render, setRender] = useState();
  const [open, setOpen] = React.useState(false);
  const [classError, setClassError] = useState(false);
  const [sectionError, setSectionError] = useState(false);
  const [usernameList, setUsernameList] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [gradeData, setGradeData] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const schema = yup
    .object({
      "First Name": yup.string().required().max(20),
      "Middle Name": yup.string().max(20),
      "Last Name": yup.string().required().max(20),
      Username: yup
        .string()
        .required()
        .notOneOf(usernameList, "Username already exists"),
      Gender: yup.string().required(),
      Type: yup.string().required(),
      Password: !editMode ? yup.string().required().min(4) : "",
      confirmpassword: !editMode
        ? yup
            .string()
            .required("Confirm Password is required")
            .oneOf([yup.ref("Password")], "Passwords must match")
        : "",
      "Contact Number": yup.lazy((value) => {
        if (value) {
          return yup
            .string()
            .min(10)
            .max(12)
            .matches(
              /^[0-9]{10}|^[0-9]{12}$/,
              "Contact number must contain numberic character only"
            );
        }
        return yup.mixed().notRequired();
      }),
      "Official Email": yup.string().required().email(),
      Class: yup.lazy((value) => {
        if (!value && classSection && !Object.keys(classSection).length) {
          return yup.string().nullable().required();
        } else if (Object.keys(classSection).length) {
          const data = Object.keys(classSection).filter((element) => {
            if (!classSection[element].length) {
              return true;
            }
            return false;
          });
          if (data.length) {
            return yup
              .string()
              .nullable()
              .required("A class must have a section or remove class");
          }
        }
        return yup.string().nullable().notRequired();
      }),

      Section: yup.lazy((value) => {
        if (!value && classSection && !Object.keys(classSection).length) {
          return yup.string().required();
        }
        return yup.string().nullable().notRequired();
      }),
    })
    .required();

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const schoolcode = location.state?.user.username.split("@")[1];
    if (location.state?.user) {
      setSchoolCode(location.state?.user.username.split("@")[1]);
    }
    fetch(`${process.env.REACT_APP_API_BASE_URL}/all-grades/${schoolcode}`)
      .then((res) => res.json())
      .then((data) => {
        setGradeList(Object.keys(data?.grade));
        setGradeData(data?.grade);
        Object.entries(data?.grade).forEach(([key, value]) => {
          if (key === Object.keys(data?.grade)[0]) {
            setSectionList(value);
          }
        });
      });
    if (location.state?.mode === "edit") {
      document.title = "CSSoch | Edit Teacher";
      setEditMode(true);
      setSchoolCode(location.state?.user.username.split("@")[1]);
      unregister("Password");
      if (!location.state.teacherDetails) {
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/teacher/details/${location.state?.id}`
        )
          .then((res) => res.json())
          .then((data) => {
            updateTeacherDetailFields(data.teacherDetails);
          });
      } else {
        updateTeacherDetailFields(location.state?.teacherDetails);
      }
    } else {
      document.title = "CSSoch | Add Teacher";
      fetch(`${process.env.REACT_APP_API_BASE_URL}/get-all-usernames`)
        .then((res) => res.json())
        .then((data) => {
          const newUsernameList = data.username.map((item) =>
            item.replace(`@${location.state?.user?.schoolcode}`, "")
          );
          setUsernameList(newUsernameList);
        });
      setisLoaded(true);
    }
  }, []);

  const updateTeacherDetailFields = (data) => {
    setTeacherDetails(data);
    setFirstName(data?.first_name);
    setMiddleName(data?.middle_name);
    setLastName(data?.last_name);
    setGender(data?.gender);
    setTeacherType(data?.type);
    setStatus(data?.status);
    setUsername(data?.username);
    setContactNumber(data?.phone);
    setOfficialEmail(data?.email);
    setPersonalEmail(data?.secondary_email);
    setClassSection(data?.grades);
    setisLoaded(true);
  };

  const handleOnGradeChange = (grade) => {
    setGrade(grade);
    gradeData &&
      Object.entries(gradeData).forEach(([key, value]) => {
        if (key === grade) {
          setSection("");
          setSectionList(value);
        }
      });
  };

  const handleCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setClassError(false);
    setSectionError(false);
  };

  const addClassSection = () => {
    if (grade in classSection) {
      classSection[grade].push(section);
      setRender(section);
    } else {
      classSection[grade] = [section];
      setRender(grade);
    }
    setGrade("");
    setSection("");
    setRender();
    unregister("Class");
    unregister("Section");
  };

  const removeClass = (classes) => {
    delete classSection[classes];
    if (Object.keys(classSection).length === 0) {
      register("Class");
      register("Section");
    }
    setRender(classes);
  };
  const removeSection = (classes, section, e) => {
    const pos = classSection[classes].indexOf(section);
    if (pos > -1) {
      classSection[classes].splice(pos, 1);
    }
    setRender(pos);
  };
  const updateTeacherDetails = (update_data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(update_data),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/teacher/details/update`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setOpen(true);
        setTimeout(() => {
          navigate("/teacher/list", { state: { user: location.state?.user } });
        }, 1000);
      });
  };

  const editModeData = (inputData) => {
    const updateDetails = { user_id: location.state?.id };
    const teacherDetailsUpdate = {};
    teacherDetailsUpdate.first_name = inputData["First Name"];
    teacherDetailsUpdate.last_name = inputData["Last Name"];
    if (teacherDetails.first_name !== inputData["First Name"]) {
      teacherDetailsUpdate.fullname = inputData["Middle Name"]
        ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
        : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    }
    if (teacherDetails.middle_name !== inputData["Middle Name"]) {
      teacherDetailsUpdate.middle_name = inputData["Middle Name"];
      teacherDetailsUpdate.fullname = inputData["Middle Name"]
        ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
        : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    }
    if (teacherDetails.last_name !== inputData["Last Name"]) {
      teacherDetailsUpdate.fullname = inputData["Middle Name"]
        ? `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`
        : `${inputData["First Name"]} ${inputData["Last Name"]}`;
    }
    if (teacherDetails.gender !== gender) {
      teacherDetailsUpdate.gender = gender;
    }
    if (teacherDetails.status !== status) {
      teacherDetailsUpdate.status = status;
    }
    if (teacherDetails.type !== teacherType) {
      teacherDetailsUpdate.type = teacherType;
    }
    if (teacherDetails.phone !== inputData["Contact Number"]) {
      teacherDetailsUpdate.phone = inputData["Contact Number"];
    }
    if (teacherDetails.email !== inputData["Official Email"]) {
      teacherDetailsUpdate.email = inputData["Official Email"];
    }
    if (teacherDetails.secondary_email !== personalEmail) {
      teacherDetailsUpdate.secondary_email = personalEmail;
    }
    teacherDetailsUpdate.grades = teacherDetails.grades;
    updateDetails.details = teacherDetailsUpdate;
    return updateDetails;
  };

  const addDetailsTeacher = (inputData) => {
    const addData = {};
    const addTeacherData = {};
    addTeacherData.first_name = inputData["First Name"];
    addTeacherData.middle_name = inputData["Middle Name"];
    addTeacherData.last_name = inputData["Last Name"];
    addTeacherData.fullname = `${inputData["First Name"]} ${inputData["Middle Name"]} ${inputData["Last Name"]}`;
    addTeacherData.gender = gender;
    addTeacherData.status = status;
    addTeacherData.username = `${inputData.Username}@${schoolCode}`;
    addTeacherData.password = inputData.Password;
    addTeacherData.phone = inputData["Contact Number"];
    addTeacherData.email = inputData["Official Email"];
    addTeacherData.secondary_email = personalEmail;
    addTeacherData.role = ["teacher"];
    addTeacherData.type = teacherType;
    addData.teacher_details = addTeacherData;
    if (!Object.keys(classSection).length) {
      setClassError(true);
      return;
    }
    addData.grades = classSection;
    return addData;
  };

  const addTeacherDetails = (addData) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addData),
    };
    fetch(`${process.env.REACT_APP_API_BASE_URL}/teacher/add`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setOpen(true);
        setTimeout(() => {
          navigate("/teacher/list", { state: { user: location.state?.user } });
        }, 1000);
      });
  };
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const onSubmit = (inputData) => {
    if (editMode) {
      if (!Object.keys(classSection).length) {
        setClassError(true);
        return;
      }
      setDisableSave(isValid);
      const updateDetails = editModeData(inputData);
      updateTeacherDetails(updateDetails);
    } else {
      const addDetails = addDetailsTeacher(inputData);
      if (addDetails) {
        addTeacherDetails(addDetails);
        setDisableSave(isValid);
      }
    }
  };

  if (!isLoaded) {
    return (
      <div style={{ backgroundColor: "#DDE9F5" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#DDE9F5" }}>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          {editMode ? "Edit" : "Add"} Teacher
          {editMode ? ` - ${username}` : ""}
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            marginTop: 5,
            padding: 20,
            borderRadius: 6,
          }}
        >
          <Typography
            style={{ color: "#00868D" }}
            mb={2}
            variant="h6"
            component="h2"
          >
            Teacher details
          </Typography>
          {editMode ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              autoHideDuration={2000}
              onClose={handleCloseSnackBar}
              sx={{ top: "10vh !important" }}
            >
              <Alert
                onClose={handleCloseSnackBar}
                severity="success"
                sx={{ width: "100%" }}
              >
                Teacher details updated successfully
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              autoHideDuration={6000}
              onClose={handleCloseSnackBar}
              sx={{ top: "10vh !important" }}
            >
              <Alert
                onClose={handleCloseSnackBar}
                severity="success"
                sx={{ width: "100%" }}
              >
                Teacher added successfully
              </Alert>
            </Snackbar>
          )}
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {classError && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={classError}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                sx={{ top: "10vh !important" }}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  At least one class and a section required
                </Alert>
              </Snackbar>
            )}
            {sectionError && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={sectionError}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
                sx={{ top: "10vh !important" }}
              >
                <Alert
                  onClose={handleCloseSnackBar}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  A class must have a section or remove class
                </Alert>
              </Snackbar>
            )}
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>First Name*</FormLabel>
                  <TextField
                    data-testid="First Name"
                    className={classes.field}
                    onChange={(e) => setFirstName(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? firstName : ""}
                    inputProps={{ style: { height: 10 } }}
                    {...register("First Name")}
                  />
                  {errors["First Name"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["First Name"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Middle Name</FormLabel>
                  <TextField
                    data-testid="Middle Name"
                    className={classes.field}
                    onChange={(e) => setMiddleName(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? middleName : ""}
                    inputProps={{ style: { height: 10 } }}
                    {...register("Middle Name")}
                  />
                  {errors["Middle Name"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["Middle Name"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Last Name*</FormLabel>
                  <TextField
                    data-testid="Last Name"
                    className={classes.field}
                    onChange={(e) => setLastName(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? lastName : ""}
                    inputProps={{ style: { height: 10 } }}
                    {...register("Last Name")}
                  />
                  {errors["Last Name"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["Last Name"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Gender*</FormLabel>
                  <Select
                    defaultValue={""}
                    data-testid="Gender"
                    {...register("Gender")}
                    className={classes.field}
                    value={gender}
                    onChange={(e) => {
                      unregister("Gender");
                      setGender(e.target.value);
                    }}
                    SelectDisplayProps={{
                      style: { paddingTop: 10, paddingBottom: 10 },
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {errors.Gender && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors.Gender.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Type*</FormLabel>
                  <Select
                    defaultValue={""}
                    data-testid="Type"
                    {...register("Type")}
                    className={classes.field}
                    value={teacherType}
                    onChange={(e) => {
                      unregister("Type");
                      setTeacherType(e.target.value);
                    }}
                    SelectDisplayProps={{
                      style: { paddingTop: 10, paddingBottom: 10 },
                    }}
                  >
                    <MenuItem value="ICT">ICT</MenuItem>
                    <MenuItem value="Class teacher">Class teacher</MenuItem>
                    <MenuItem value="Subject teacher">Subject teacher</MenuItem>
                  </Select>
                  {errors.Type && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors.Type.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Status*</FormLabel>
                  <RadioGroup
                    row
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <Stack spacing={2} direction="row">
                      <FormControlLabel
                        value="active"
                        control={<Radio />}
                        data-testid="Active"
                        label="Active"
                      />
                      <FormControlLabel
                        value="inactive"
                        control={<Radio />}
                        data-testid="In-active"
                        label="In-active"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Username*</FormLabel>
                  <TextField
                    data-testid="Username"
                    className={classes.field}
                    onChange={(e) => setUsername(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? username : ""}
                    InputProps={{
                      style: { height: 44 },
                      endAdornment: (
                        <InputAdornment
                          style={{
                            visibility: editMode ? "hidden" : "visible",
                          }}
                          position="end"
                        >
                          @{schoolCode}
                        </InputAdornment>
                      ),
                      readOnly: !!editMode,
                    }}
                    {...register("Username")}
                  />
                  {errors.Username && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors.Username.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              {!editMode && (
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Password*</FormLabel>
                    <TextField
                      data-testid="Password"
                      type="password"
                      className={classes.field}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{ style: { height: 44 } }}
                      {...register("Password", {
                        required: "Password is a required field",
                      })}
                    />
                    {errors.Password && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors.Password.message}
                      </span>
                    )}
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "#00868D",
                      }}
                    >
                      Password should minimum contain 8 characters, at least one
                      uppercase, one lowercase, one number and one special case
                      character
                    </span>
                  </FormControl>
                </Grid>
              )}
              {!editMode && (
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Confirm Password*</FormLabel>
                    <TextField
                      data-testid="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      className={classes.field}
                      // onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{
                        style: { height: 44 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggleconfirmpasswordvisibility"
                              onClick={handleClickShowConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register("confirmpassword", {
                        required: "Password is a required field",
                      })}
                    />
                    {errors.confirmpassword && (
                      <span
                        style={{
                          marginTop: -15,
                          marginBottom: 10,
                          color: "red",
                        }}
                      >
                        {errors.confirmpassword.message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Typography
              style={{ color: "#00868D" }}
              mb={2}
              variant="h6"
              component="h2"
            >
              Teacher assigned to
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Class*</FormLabel>
                  <Select
                    data-testid="Class"
                    className={classes.field}
                    {...register("Class")}
                    value={grade}
                    onChange={(e) => {
                      unregister("Class");
                      handleOnGradeChange(e.target.value);
                    }}
                    SelectDisplayProps={{
                      style: { paddingTop: 10, paddingBottom: 10 },
                    }}
                  >
                    {gradeList &&
                      gradeList.map((grade) => (
                        <MenuItem value={grade} key={grade}>
                          {grade}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors["Class"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["Class"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Section*</FormLabel>
                  <Select
                    data-testid="Section"
                    className={classes.field}
                    {...register("Section")}
                    value={section}
                    onChange={(e) => {
                      unregister("Section");
                      setSection(e.target.value);
                    }}
                    SelectDisplayProps={{
                      style: { paddingTop: 10, paddingBottom: 10 },
                    }}
                  >
                    {sectionList &&
                      sectionList.map((section) => (
                        <MenuItem value={section} key={section}>
                          {section}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors["Section"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["Section"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <FormLabel />
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#00868D", marginTop: 30 }}
                    onClick={addClassSection}
                    disabled={!grade || !section}
                  >
                    Add
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={10} style={{ maxWidth: "100%", width: "100%" }}>
                {classSection &&
                  Object.entries(classSection).map(([classes, sections]) => (
                    <>
                      <hr />
                      <Stack
                        direction="row"
                        style={{ flexWrap: "wrap", width: "100%" }}
                      >
                        <Box
                          sx={{
                            width: 90,
                            height: "auto",
                            backgroundColor: "#2F4F4F",
                            color: "white",
                            alignItems: "center",
                            borderRadius: 25,
                            margin: "5px",
                            padding: 0,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Class {classes}
                          <CloseIcon
                            fontSize="string"
                            sx={{ marginLeft: 1, cursor: "pointer" }}
                            onClick={() => removeClass(classes)}
                          />
                        </Box>

                        <hr
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            height: 0,
                            width: "5%",
                            alignSelf: "center",
                            margin: "5px",
                          }}
                        />
                        {sections.sort().map((section, index) => (
                          <Box
                            key={section}
                            sx={{
                              width: "auto",
                              height: "auto",
                              backgroundColor: "#2F4F4F",
                              color: "white",
                              alignItems: "center",
                              borderRadius: 25,
                              marginRight: 1,
                              margin: "5px",
                            }}
                          >
                            <span
                              style={{
                                alignItems: "center",
                                paddingLeft: 7,
                                display: "flex",
                                alignItems: "center",
                                padding: "5px",
                              }}
                              className="legend-label"
                            >
                              Section {section}
                              <CloseIcon
                                fontSize="string"
                                sx={{ marginLeft: 1, cursor: "pointer" }}
                                onClick={() => {
                                  removeSection(classes, section);
                                }}
                              />
                            </span>
                          </Box>
                        ))}
                      </Stack>
                    </>
                  ))}
                <hr />
              </Grid>
            </Grid>
            <Typography
              style={{ color: "#00868D" }}
              mb={2}
              variant="h6"
              component="h2"
            >
              Teacher contact details
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Contact number</FormLabel>
                  <TextField
                    data-testid="Contact Number"
                    className={classes.field}
                    onChange={(e) =>
                      setContactNumber(e.target.value.replace(/\D/g, ""))
                    }
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? contactNumber : ""}
                    inputProps={{ style: { height: 10 } }}
                    {...register("Contact Number")}
                  />
                  {errors["Contact Number"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["Contact Number"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Official Email*</FormLabel>
                  <TextField
                    data-testid="Official Email"
                    className={classes.field}
                    onChange={(e) => setOfficialEmail(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? officialEmail : ""}
                    inputProps={{ style: { height: 10 } }}
                    {...register("Official Email")}
                  />
                  {errors["Official Email"] && (
                    <span
                      style={{
                        marginTop: -15,
                        marginBottom: 10,
                        color: "red",
                      }}
                    >
                      {errors["Official Email"].message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <FormLabel>Personal Email</FormLabel>
                  <TextField
                    data-testid="Personal Email"
                    className={classes.field}
                    onChange={(e) => setPersonalEmail(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editMode ? personalEmail : ""}
                    inputProps={{ style: { height: 10 } }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <h6 style={{ display: "none" }}>{render}</h6>
            <Stack
              spacing={2}
              direction="row"
              style={{ justifyContent: "center" }}
            >
              <Button
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#00868D" }}
                disabled={disableSave}
              >
                Save
              </Button>

              <Button
                onClick={() =>
                  navigate("/teacher/list", {
                    state: { user: location.state?.user },
                  })
                }
                variant="text"
                style={{ color: "black" }}
              >
                Cancel
              </Button>
            </Stack>
          </form>
        </div>
      </Container>
    </div>
  );
}
