import { Backdrop, CircularProgress } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { authUserContext } from "../Contexts/AuthUser";
import { SetSchoolName } from "../Contexts/schoolName";
import PrimaryNav from "../NavBars/PrimaryNav";
import ResponsiveDrawer from "./Sidebar/responsiveDrawer";
const PrimaryNavBarContext = createContext();

function Layout({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const { user } = useContext(authUserContext);
  const location = useLocation();
  const [
    isAdminDashboardOrTeacherClassess,
    setIsAdminDashboardOrTeacherClassess,
  ] = useState(false);
  const [isTeacherDashboard, setIsTeacherDashboard] = useState(false);
  const [teacherGrade, setTeacherGrade] = useState(false);
  const [teacherSection, setTeacherSection] = useState(false);
  const [navbarModuleData, setNavbarModuleData] = useState({});
  const [isModulePage, setIsModulePage] = useState(false);
  const [isTeacherClassesPage, setIsTeacherClassesPage] = useState(false);
  useEffect(() => {
    if (user.is_authenticated) {
      if (user.role.includes("superadmin")) {
        setIsSuperAdmin(true);
        setIsAdmin(true);
      }
      if (user.role.includes("admin") || user.role.includes("superadmin")) {
        setIsAdmin(true);
      } else if (user.role.includes("teacher")) {
        setIsTeacher(true);
      } else {
        setIsStudent(true);
      }
      setIsAuthenticated(true);
      if (!user.role.includes("student")) {
        if (sessionStorage.getItem("module_progress_data"))
          sessionStorage.setItem(
            "module_progress_data",
            sessionStorage.getItem("module_progress_data")
          );
        else sessionStorage.setItem("module_progress_data", JSON.stringify({}));
        if (sessionStorage.getItem("module_analytics_data"))
          sessionStorage.setItem(
            "module_analytics_data",
            sessionStorage.getItem("module_analytics_data")
          );
        else
          sessionStorage.setItem("module_analytics_data", JSON.stringify({}));
        if (sessionStorage.getItem("module_assignment_data"))
          sessionStorage.setItem(
            "module_assignment_data",
            sessionStorage.getItem("module_assignment_data")
          );
        else
          sessionStorage.setItem("module_assignment_data", JSON.stringify({}));
        if (sessionStorage.getItem("skills_analytics_data"))
          sessionStorage.setItem(
            "skills_analytics_data",
            sessionStorage.getItem("skills_analytics_data")
          );
        else
          sessionStorage.setItem("skills_analytics_data", JSON.stringify({}));
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/landing`;
    }
  }, []);
  return (
    <div>
      {isAuthenticated ? (
        <PrimaryNavBarContext.Provider
          value={{
            isAdminDashboardOrTeacherClassess,
            setIsAdminDashboardOrTeacherClassess,
            teacherGrade,
            setTeacherGrade,
            teacherSection,
            setTeacherSection,
            isTeacherDashboard,
            setIsTeacherDashboard,
            navbarModuleData,
            setNavbarModuleData,
            setIsModulePage,
            isModulePage,
            isTeacherClassesPage,
            setIsTeacherClassesPage,
          }}
        >
          {!location.pathname.includes("reset") ? <PrimaryNav /> : <></>}
          <div>
            {isAdmin && !isStudent && user && (
              <SetSchoolName>
                <ResponsiveDrawer
                  user={user}
                  isSuperAdmin={isSuperAdmin}
                  isTeacher={isTeacher}
                >
                  {children}
                </ResponsiveDrawer>
              </SetSchoolName>
            )}
            {isTeacher && !isStudent && user && (
              <>
                {location.pathname === "/admin/dashboard" ? (
                  <SetSchoolName>
                    <ResponsiveDrawer
                      user={user}
                      isSuperAdmin={isSuperAdmin}
                      isTeacher={isTeacher}
                    >
                      {children}
                    </ResponsiveDrawer>
                  </SetSchoolName>
                ) : (
                  <>{children}</>
                )}
              </>
            )}
            {isStudent && !isAdmin && user && <>{children}</>}
            {!isStudent && !isTeacher && !isAdmin && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </div>
        </PrimaryNavBarContext.Provider>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}

export { Layout, PrimaryNavBarContext };
