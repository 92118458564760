import { useDispatch } from "react-redux";
import { toggleSection } from "../../features/CommunityData/CommunityState";

const ExpandableSectionHandler = ({
  children,
  initialExpandedSections = {},
}) => {
  const dispatch = useDispatch();

  const handleExpandSection = (section) => {
    dispatch(toggleSection(section));
  };

  return children({
    expandedSections: initialExpandedSections,
    handleExpandSection,
  });
};

export default ExpandableSectionHandler;
