import Tooltip from "../Tooltips/Tooltip";
import {
  CardActionArea,
  Typography,
  CardMedia,
  CardContent,
  Card,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../Styles/Playground.css";
import { usePlaygroundProjectAssets } from "./PlaygroundProjectAssets";

function CommunityProjectCard(props) {
  const {
    project,
    gotoPlayground,
    playgroundProjectIdList,
    isPlayground = false,
    handleDeleteProjectPopupOpen = () => {},
  } = props;
  const { projectImage, projectIcon } = usePlaygroundProjectAssets(
    project,
    playgroundProjectIdList
  );

  return (
    <Tooltip
      key={Math.random().toString(36).substring(2, 15)}
      title={project?.student_name}
      placement="top"
    >
      <Card
        key={project?.project_id}
        className="project-card user-project-card"
        onClick={() => gotoPlayground(project, isPlayground ? "edit" : "view")}
        sx={{
          backgroundColor: isPlayground
            ? project?.published
              ? "#25A596"
              : "#AECB2A"
            : "#25A596",
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            image={projectImage}
            alt="project Img"
            className="project-card-content-container"
          />
          <CardContent
            sx={{
              justifyContent: "space-between",
            }}
            className="project-card-content-content"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "80%",
              }}
            >
              {projectIcon}
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                className="project-title"
              >
                {project.title}
              </Typography>
            </div>
            {isPlayground && (
              <DeleteIcon
                className="project-delete-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteProjectPopupOpen(project);
                }}
              />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
}

export default CommunityProjectCard;
