import {
  Container,
  CardActionArea,
  Typography,
  CardMedia,
  CardContent,
  Card,
} from "@mui/material";
import "../../Styles/Playground.css";
import AddIcon from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import Tooltip from "../../Components/Tooltips/Tooltip";
import ProjectCardsContainer from "../../Components/PlaygroundComponents/ProjectCardsContainer";
import CommunityProjectCard from "../../Components/PlaygroundComponents/CommunityProjectCard";

function ExpandableProjectGroup({
  projectsExpandedSection,
  projectsSectionWise,
  handleExpandSection,
  userId,
  playgroundProjectIdList,
  gotoPlayground,
  noProjectsPublished,
}) {
  const defaultProjectsCount = 4;

  if (noProjectsPublished) {
    return (
      <Container className="playground-projects-container" maxWidth={false}>
        <div className="no-community-projects">
          <p>No Projects Shared Yet</p>
        </div>
      </Container>
    );
  }

  return Object.keys(projectsSectionWise)?.map((section) => {
    return (
      <ProjectCardsContainer>
        <Typography
          variant="h4"
          gutterBottom
          component="h1"
          className="playground-headings"
          sx={{
            fontSize: "3.5vh !important",
            fontFamily: "rajdhani-semibold",
          }}
        >
          Section {section}
        </Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            marginLeft: "1.5vw",
          }}
          key={section}
        >
          {projectsSectionWise[section].length > 0 ? (
            projectsSectionWise[section]
              ?.slice(
                0,
                projectsExpandedSection[section]
                  ? projectsSectionWise[section].length
                  : defaultProjectsCount
              )
              .map((project) => {
                if (project.student_id !== userId)
                  return (
                    <CommunityProjectCard
                      key={project.project_id}
                      project={project}
                      gotoPlayground={gotoPlayground}
                      playgroundProjectIdList={playgroundProjectIdList}
                    />
                  );
                return null;
              })
          ) : (
            <div className="no-community-projects">
              <p>No Projects Shared Yet</p>
            </div>
          )}
          {projectsSectionWise[section]?.length > defaultProjectsCount && (
            <Tooltip
              key={Math.random().toString(36).substring(2, 15)}
              title={
                projectsExpandedSection[section]
                  ? "Collapse Section"
                  : "Expand Section"
              }
              placement="top"
            >
              <Card
                className="project-card user-project-card"
                onClick={() => {
                  handleExpandSection(section);
                }}
                sx={{
                  backgroundColor: "#DDE9F5",
                }}
              >
                <CardActionArea>
                  <CardMedia
                    alt="project Img"
                    className="project-card-content-container"
                  >
                    {projectsExpandedSection[section] ? (
                      <Remove className="project-card-add-icon" />
                    ) : (
                      <AddIcon className="project-card-add-icon" />
                    )}
                  </CardMedia>
                  <CardContent className="project-card-content-content">
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="span"
                      className="project-title"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      {!projectsExpandedSection[section]
                        ? "Show More"
                        : "Show Less"}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Tooltip>
          )}
        </div>
      </ProjectCardsContainer>
    );
  });
}

export default ExpandableProjectGroup;
