import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFile } from "../../features/FilesDownloader/FilesDownloader";
import "./Downloader.css";
import DownloadItem from "./DownloadItem";

function DownloadProgressBox() {
  const files = useSelector((state) => state.fileDownloader.files);
  const dispatch = useDispatch();

  const handleRemove = (e) => {
    dispatch(removeFile(e));
  };

  return (
    <>
      {files.length > 0 && (
        <div
          className="downloader"
          style={{
            position: "fixed",
            right: "18px",
            bottom: "18px",
            zIndex: 1002,
          }}
        >
          <div className="card">
            <div className="card-header">
              <Typography variant="h6">
                Report Downloading In Progress ...
              </Typography>
            </div>
            <ul className="list-group list-group-flush">
              {files.map((file, idx) => (
                <DownloadItem
                  key={idx}
                  removeFile={() => handleRemove(file.id)}
                  {...file.info}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default DownloadProgressBox;
