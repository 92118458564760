import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import teacherDashboardModulesDataReducer from "../features/TeacherUserData/ModulesData";
import studentPerformanceDataReducer from "../features/TeacherUserData/StudentPerformanceData";
import teacherDashboardSidebarDataReducer from "../features/TeacherUserData/DashboardSidebarData";
import classesDataReducer from "../features/TeacherUserData/ClassesData";
import NavbarActionsReducer from "../features/PrimaryNavbarData/NavbarActions";
import communityStateReducer from "../features/CommunityData/CommunityState";
import FileDownlaodReducer from "../features/FilesDownloader/FilesDownloader";

export const userLogout = "UserLogout";

const appReducers = combineReducers({
  dashboardModulesData: teacherDashboardModulesDataReducer,
  studentPerfromanceData: studentPerformanceDataReducer,
  dashboardSidebarData: teacherDashboardSidebarDataReducer,
  classesData: classesDataReducer,
  navbarActions: NavbarActionsReducer,
  communityState: communityStateReducer,
  fileDownloader: FileDownlaodReducer,
});

const rootReducer = (state, action) => {
  if (action.type === userLogout) {
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
