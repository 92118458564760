import React from "react";

function ProjectCardsContainer({ children, props }) {
  return (
    <div className="project-cards-container" {...props}>
      {children}
    </div>
  );
}

export default ProjectCardsContainer;
