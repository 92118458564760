import PythonPlaygroundSnapshot from "../../Assets/snapshots/PythonPlaygroundSnapshot.svg";
import PythonGraphicsPlaygroundSnapshot from "../../Assets/snapshots/PythonGraphicsPlaygroundSnapshot.svg";
import FoxSnapshotImg from "../../Assets/snapshots/MotherFoxDownSnapshot.svg";
import GameSnapshotImg from "../../Assets/snapshots/GameSnapshot.jpg";
import PuzzleSnapshotImg from "../../Assets/snapshots/PuzzleSnapshot.jpg";
import PythonButton from "../ModuleComponentsButtons/PythonButton";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";

export const usePlaygroundProjectAssets = (
  project,
  playgroundProjectIdList
) => {
  const getProjectImage = () => {
    if (project?.projectType === "python") {
      return PythonPlaygroundSnapshot;
    } else if (project?.projectType === "pythonGraphics") {
      return PythonGraphicsPlaygroundSnapshot;
    } else if (
      playgroundProjectIdList.indexOf(project?.strapi_game_set_id) % 3 ===
      2
    ) {
      return FoxSnapshotImg;
    } else if (
      playgroundProjectIdList.indexOf(project?.strapi_game_set_id) % 3 ===
      1
    ) {
      return GameSnapshotImg;
    } else {
      return PuzzleSnapshotImg;
    }
  };

  const getProjectIcon = () => {
    if (
      project?.projectType === "python" ||
      project?.projectType === "pythonGraphics"
    ) {
      return <PythonButton key={project} status={"transparent"} icon={true} />;
    } else {
      return <SportsEsportsOutlinedIcon />;
    }
  };

  return {
    projectImage: getProjectImage(),
    projectIcon: getProjectIcon(),
  };
};
