import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

const initialState = {
  open: false,
  files: [],
};

export const FileDownloaderSlice = createSlice({
  name: "fileDownloader",
  initialState,
  reducers: {
    updateDownloadReport: (state, action) => {
      state.open = action.payload?.open;
      state.url = action.payload?.url;
    },
    downloadFile: (state, action) => {
      const file = action.payload;
      state.files = [...state.files, { info: file, id: uuid() }];
      state.open = true;
    },
    removeFile: (state, action) => {
      const id = action.payload;
      state.files = state.files.filter((file) => file.id !== id);
      if (state.files.length === 0) state.open = false;
    },
  },
});

export const { updateDownloadReport, downloadFile, removeFile } =
  FileDownloaderSlice.actions;
export default FileDownloaderSlice.reducer;
