import React from "react";
import HorizontalBar from "../../../Assets/Images/HorizontalBar.svg"
import {
  Dialog, DialogTitle, IconButton, styled,
} from '@mui/material/';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));

export default function LevelButtons(props) {
  let pythonLevelButtonLabel = 1;
  return (
    <div className="python-button-holder">
      {props.levels.map((lvl, index) => {
        if (index === props.levels.length - 1) {
          return lvl.DemoLevel ? 
            <BootstrapTooltip placement="bottom" title="Demo Level">
              <button
                className={`demo-level-btn ${props.currentLevel === index + 1 ? "active" : ""}` }
                key={`btn-${index}`}
                onClick={() => {
                  props.setCurrentLevel(index + 1);
                }}
              />
            </BootstrapTooltip>
            :
            <button
              className={props.currentLevel === index + 1 ? "active" : ""}
              style={props.statusStorage?.python_levels[index]?.status === "completed" ? { backgroundColor: "#25A596" } : props.statusStorage?.python_levels[index]?.status === "ongoing" ? { backgroundColor: "#AECB2A" } : {}}
              key={`btn-${index}`}
              onClick={() => {
                props.setCurrentLevel(index + 1);
              }}
              >
              { pythonLevelButtonLabel++ }
            </button>
        }
        return  lvl.DemoLevel ? (
          <>
            <BootstrapTooltip placement="bottom" title="Demo Level">
              <button
                className={`demo-level-btn ${props.currentLevel === index + 1 ? "active" : ""}` }
                key={`btn-${index}`}
                onClick={() => {
                  props.setCurrentLevel(index + 1);
                }}
              />
            </BootstrapTooltip>
            <img key={`img-${index}`} src={HorizontalBar} />
          </>
          )
          :
          <>
            <button
              className={props.currentLevel === index + 1 ? "active" : ""}
              style={props.statusStorage?.python_levels[index]?.status === "completed" ? { backgroundColor: "#25A596" } : props.statusStorage?.python_levels[index]?.status === "ongoing" ? { backgroundColor: "#AECB2A" } : {}}
              key={`btn-${index}`}
              onClick={() => {
                props.setCurrentLevel(index + 1);
              }}
            >
              { pythonLevelButtonLabel++ }
            </button>
            <img key={`img-${index}`} src={HorizontalBar} />
          </>
        })}
    </div>
  );
}
